import React, { useState, useEffect,Fragment } from 'react'
import { makeStyles, TableBody, TableRow, TableCell, Toolbar, Table, TableHead } from '@material-ui/core';
import useTable from "../components/useTable";
import Header from "../components/header";
import { DatePicker } from "@material-ui/pickers";
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Controls from "../components/controls/Controls";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ClipLoader from "react-spinners/ClipLoader";
import moment from "moment";
import { useHistory } from "react-router-dom"

const useStyles = makeStyles(theme => ({
    root: {
      width: '100%',
      '& > * + *': {
        marginTop: theme.spacing(2),
      },
    },pageContent: {
        marginTop: theme.spacing(5),
        padding: theme.spacing(3)
    },
    searchInput: {
        width: '50%'
    },
    spaceSelect: {
        margin: '20px'
    },
    pullright: {
        position: 'absolute',
        right: 10,
        fontWeight: 'bold'
    },
    title: {
        margin: '20px',
        color: '#1a1ab7'
    },
    success : {
        color: 'green'
    },
    failed : {
        color: 'red'
    },
    hideClass:{
        display:'none'
    },
    showClass:{
        display:'table-row'
    }
}));

const headCells = [
    { id: 'id', label: 'Ref ID' },
    { id: 'OrderDate', label: 'OrderDate' },
    { id: 'OrderNumb', label: 'Purchase Order No' },
    { id: 'totalOrderedQty', label: 'Total Quantity' },
    { id: 'OrderAmount', label: 'OrderAmount' },
    { id: 'OrderStatus', label: 'OrderStatus' }, 
    { id: 'RefBillNo', label: 'Vendor Bill No' }, 
    { id: 'Action', label: 'Action' }   
];

export default function Vieworder() {
    let history = useHistory(); 

    const classes = useStyles();
    const [branchlist,setBranchlist] = useState([{id:"-1", title: "Select Branch"}]);
    const [branchcode, setBranchcode] = useState("-1");
    const [records, setRecords] = useState([]);

    let [color, setColor] = useState("#226cd7");
    let [loading, setLoading] = useState(false);
    let [bdloading, setBdloading] = useState(false);
    const [branchdetail, setBranchdetails] = useState({});


    // const [filterFn, setFilterFn] = useState({ fn: items => { return items; } });
    const [selectedStartDate, setSelectedStartDate] = useState(
         () => {
            const datet = new Date();
            const previous = new Date(datet.getTime());
            previous.setDate(datet.getDate() - 1);

            let month = previous.getMonth() +1;
            let date = previous.getDate();
            if(date < 10){
                date = "0"+date;
            }
            if(month < 10){
                month = "0"+month;
            }
            return new Date().getFullYear() +"-" + month + "-" + date;

        // let d = new Date();
        // // let month = d.getMonth();
        // // d.setMonth(d.getMonth() - 1);
        // // while (d.getMonth() === month) {
        // //     d.setDate(d.getDate() - 1);
        // // }
        // // let date = d.getDate();
        // if(date < 10){
        //     date = "0"+date;
        // }
        // if(month < 10){
        //     month = "0"+month;
        // }
        // return new Date().getFullYear() +"-" + month + "-" + date;

    }

    );

    const handleInputChange = (evt) => {
        setBranchcode(evt.target.value);
    }

    useEffect(() => {
        const requestOptions = {
            method: 'GET',
            headers: { 'Content-Type': 'application/json', 'authorization' :  sessionStorage.getItem('access_token') }
        };
        fetch(process.env.REACT_APP_API_PREFIX_URL + 'branchdetail/list', requestOptions)
            .then(response => response.json())
            .then(result => {
                if(result.status === "Failed" && result.message === "Invalid token"){
                    history.push("/");
                }else{
                    setBranchlist(result["response"]);
                    setBranchcode(result["response"][1]["id"]);
                }
            }).catch(error => {
                            console.log(error);
                            setBranchlist([{id:"", title: "Select Branch"}])
                        })
                }, []);

    const [selectedEndDate, setSelectedEndDate] = useState(
            () => {

                let month = new Date().getMonth()+1;
                let date = new Date().getDate();
        
                if(date < 10){
                    date = "0"+date;
                }
                if(month < 10){
                    month = "0"+month;
                }
                return new Date().getFullYear() +"-" + month + "-" + date;
        
            }
    
    );

    useEffect(() => {
        // console.log(selectedStartDate,"start");
        // console.log(selectedEndDate,"end");
        if(branchcode != "-1"){
            const requestOptions = {
                method: 'GET',
                headers: { 'Content-Type': 'application/json', 'authorization' :  sessionStorage.getItem('access_token') }
            };
            setLoading(true);
            fetch(process.env.REACT_APP_API_PREFIX_URL + 'porder/list?startdate='+selectedStartDate+'&enddate='+selectedEndDate+'&branchcode='+branchcode, requestOptions)
            .then(response => response.json())
            .then(result => {

                if(result.status === "Failed" && result.message === "Invalid token"){
                    history.push("/");
                }else{
                    let updatedRes = result["response"].map((item, idx) => {
                        item["expandcollapseFlag"] = false;
                        item["totalOrderedQty"] = item["poPrimaryData"]["Items"].reduce((n, {OrderQty}) => n + OrderQty, 0)
                        return item;
                    });
                    // console.log(JSON.stringify(updatedRes));
                    // debugger;
                    setRecords(updatedRes);
                    setLoading(false);
                }
            })
              .catch(error => {
                  console.log(error);
                  setRecords([]);
                  setLoading(false);
              })
        } else{
            setRecords([])
        }
       
    }, [selectedStartDate, selectedEndDate,branchcode ]);

    useEffect(() => {
        if (!!branchcode && branchcode != "-1") {
            setBdloading(true);
            const requestOptions = {
                method: 'GET',
                headers: { 'Content-Type': 'application/json', 'authorization' :  sessionStorage.getItem('access_token') }
            };
            fetch(process.env.REACT_APP_API_PREFIX_URL + 'branchdetail/'+branchcode, requestOptions)
                .then(response => response.json())
                .then(result => 
                    {
                        if(result.status === "Failed" && result.message === "Invalid token"){
                            history.push("/");
                        }else{
                            setBranchdetails(result["response"]);
                            setBdloading(false);
                        }
                    }).catch(error => {
                                console.log(error);
                                setBdloading(false);
                                setBranchdetails({})
                            })
        } else{
            setBranchdetails({})
        }
    }, [branchcode, setBranchdetails]);

    const formatDatedisplay = (datevalue) =>{
        const newDate = datevalue.toString().slice(6,8)+"-"+datevalue.toString().slice(4,6)+"-"+datevalue.toString().slice(0,4);
        return newDate;
    }


    const {
        TblContainer,
        TblHead,
        TblPagination,
        recordsAfterPagingAndSorting,
        getTotalRecords,
        getTotalSuccessecords,
        getTotalFailedecords
    } = useTable(records, headCells);


    const handleDateChange1 = (sdate) => {
        let month = sdate.getMonth()+1;
        let date = sdate.getDate();

        if(date < 10){
            date = "0"+date;
        }
        if(month < 10){
            month = "0"+month;
        }
        setSelectedStartDate( sdate.getFullYear() +"-" + month + "-" + date);  
    }

    const handleDateChange2 = (edate) => {
        let month = edate.getMonth()+1;
        let date = edate.getDate();

        if(date < 10){
            date = "0"+date;
        }
        if(month < 10){
            month = "0"+month;
        }
        setSelectedEndDate( edate.getFullYear() +"-" + month + "-" + date);  
    }

    const expandToviewOrderProduct = (evt, index) => {
        let curList = [...records];
        let selectedOrderList = records[index];
        selectedOrderList["expandcollapseFlag"] = !selectedOrderList["expandcollapseFlag"];
        curList[index] = selectedOrderList;
        setRecords(curList);
    }

    return (
        <div>
            <Header />

            <div >
                <div>
                    <Typography variant="h6" className={classes.title}>
                   View Purchase Order
                    </Typography>
                </div>

                <Grid container spacing={3}>
                    <Grid item  sm={3}>
                        <div className = {classes.spaceSelect}>
                            <Controls.Select
                                className = {classes.selectWidth}
                                label = "Select Branch"
                                name="deliverybranch"
                                value={branchcode}
                                onChange={handleInputChange}
                                options={branchlist}
                                key_id ="id"
                                key_title ="title"
                            />
                        </div>
                    </Grid>

                    {
                bdloading ? 
                <Grid item sm={6}>  
                <ClipLoader
                    color={color}
                    loading={bdloading}
                    size={50}
                    cssOverride= { {display: "block",   margin: '0 auto'} }
                /> 
                </Grid>
                : !!branchcode && !!branchdetail && !!branchdetail.address ? (
                    <Grid item sm={6}>  
                      <Typography variant="h6">
                      Delivery Location: 
                      </Typography>
                      {branchdetail.address}
                      </Grid>
                  ) : ("")

            }
    
            </Grid>
            <Toolbar>
            <Fragment>
            <DatePicker 
             label="Start Date"
            value={selectedStartDate} 
            onChange={handleDateChange1} 
            format="yyyy-MM-dd"
            />
            <div className = {classes.spaceSelect}></div>
            <DatePicker 
             label="End Date"
            value={selectedEndDate} 
            onChange={ handleDateChange2} 
            format="yyyy-MM-dd"
               />
              </Fragment>
              {/* <div className = {classes.spaceSelect}></div>
              <div className = {classes.spaceSelect}></div> */}
              <Typography> 
                Total PO Received: &nbsp;{getTotalRecords()}&nbsp; <br/> 
                {/* Successful Executions: <span className= {classes.success}>{getTotalSuccessecords()}</span> <br/>
                Failed Executions: <span  className= {classes.failed}> {getTotalFailedecords()} </span> */}
            </Typography>
            {/* <div className = {classes.spaceSelect}></div> */}
            {/* <div variant="h6" align="right" className={classes.pullright}> Total &nbsp;{getTotalRecords()}&nbsp;
             no of records found. </div> */}
           
            </Toolbar>

            { loading ? 
                    <div>
                    <ClipLoader
                        color={color}
                        loading={loading}
                        size={50}
                        cssOverride= { {display: "block",   margin: '0 auto'} }
                    /> </div>:
                <div>
                <TblContainer>
                    <TblHead />
                    <TableBody>
                        {
                            recordsAfterPagingAndSorting().map((order, index) =>
                                (
                                    <>
                                    <TableRow key={index}>
                                    <TableCell>{index+1}</TableCell>
                                     <TableCell>{formatDatedisplay(order["poPrimaryData"]["OrderDate"])}</TableCell>
                                    <TableCell>{order["poPrimaryData"]["OrderNumb"]}</TableCell>
                                    <TableCell>{order["totalOrderedQty"]}</TableCell>
                                    <TableCell>{order["poPrimaryData"]["OrderAmount"]}</TableCell>
                                    <TableCell>{order["poPrimaryData"]["OrderStatus"]}</TableCell>
                                    <TableCell>{order["poPrimaryData"]["RefBillNo"]}</TableCell>
                                    <TableCell>
                                        {order.expandcollapseFlag ?
                                        <ExpandLessIcon onClick={(evt) => expandToviewOrderProduct(evt, index)}>

                                        </ExpandLessIcon> : <ExpandMoreIcon onClick={(evt) => expandToviewOrderProduct(evt, index)}></ExpandMoreIcon>
                                        }
                                        </TableCell>
                                 </TableRow>
                                  <TableRow className={!!order.expandcollapseFlag ? classes.showClass : classes.hideClass}>
                                    <TableCell colSpan={7}>
                                    <Table>
                                    <TableHead>
                                        <TableRow>
                                        <TableCell>Item Name and Code</TableCell>
                                        <TableCell>Order Qty</TableCell>
                                        <TableCell>ItemRate</TableCell>
                                        <TableCell>ItemAmount</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                    { 
                                        order["poPrimaryData"]["Items"].map((subitem, subindex) =>
                                        <TableRow key={"subindex"+subindex}>
                                        <TableCell>{subitem.ItemName + " ("+ subitem.ItemCode + ")"}</TableCell>
                                        <TableCell>{subitem.OrderQty}</TableCell>
                                        <TableCell>{subitem.ItemRate}</TableCell>
                                        <TableCell>{subitem.ItemAmount}</TableCell>
                                        </TableRow>
                                    )}
                                    </TableBody>
                                    </Table>
                                    </TableCell>
                                  </TableRow>
                                  </>
                                  
                                 )
                            )
                        }
                    </TableBody>
                </TblContainer>
                <TblPagination />
                </div>
            }
            </div>
        </div>
    )
}