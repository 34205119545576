import React , {useEffect, useState} from 'react';
import { Grid,Paper, Avatar, TextField, Button } from '@material-ui/core'
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import { useForm , Controller} from 'react-hook-form';
import axios from 'axios';
import { useHistory } from "react-router-dom"
import  CryptoJS  from 'crypto-js';
import AES  from "crypto-js/aes";
import  {Buffer}  from 'buffer';
import ClipLoader from "react-spinners/ClipLoader";
import MuiAlert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';
function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }
const Login = () => {
    let history = useHistory(); 
    let [loading, setLoading] = useState(false);
    let [color, setColor] = useState("#226cd7");

    const [open, setOpen] = React.useState(false);
    const [severity, setSeverity] = React.useState("success");
    const [msg, setMsg] =  React.useState("");
   
    useEffect(() => {
        sessionStorage.setItem('session_name', '');
        sessionStorage.setItem('session_role', '');
        sessionStorage.setItem('access_token', '');
        const query = window.location.search.substring(1);
        if(!!query){
            const queryValue = query.split("=");
            if(queryValue.length === 2){
                setLoading(true);
                let decodeURL = decodeURIComponent(queryValue[1]);
                const de_cipherByte = AES.decrypt(decodeURL, 'POORVIKA');
                const de_string = de_cipherByte.toString(CryptoJS.enc.Utf8);
                const otext = Buffer.from(de_string, 'base64').toString('ascii');
                const s_text = otext.split("::");
                if(s_text.length === 2){
                    let sub_value = {
                        username: s_text[0],
                        password: s_text[1],
                    }
                    axios.post(`${process.env.REACT_APP_API_PREFIX_URL}user/authenticate`, sub_value)
                        .then(res => {
                            if (res.status == 200) {
                                if (!!res.data && res.data.status === "success") {
                                    // sessionStorage.setItem('session',JSON.stringify(values));
                                    sessionStorage.setItem('session_name', res.data.response.username);
                                    sessionStorage.setItem('session_role',  res.data.response.role);
                                    sessionStorage.setItem('access_token', res.data.response.accesstoken)
                                    history.push("/pcorder");
                                }
                            } else {
                                setLoading(false);
                                setOpen(true);
                                setSeverity('error')
                                setMsg("Login Failed.");

                            }
                    }).catch(function(error) {
                        setLoading(false);
                       if(!!error && !!error.response){
                        setOpen(true);
                        setSeverity('error')
                        setMsg(error.response.data.msg);
                       }
                      });
                }
            } else{
                setLoading(false);
            }
           
        }
        

     }, []);

    const { handleSubmit, control, formState: { errors } } = useForm({ defaultValues: { username: '', password: '' } });
    const onSubmit = async (values) => {
        if(!values.username & !values.password){
            setLoading(false);
            return false;
        }
        setLoading(true);
        let sub_value = {
            username: values.username,
            password: values.password
        }
        axios.post(`${process.env.REACT_APP_API_PREFIX_URL}user/authenticate`, sub_value)
            .then(res => {
                debugger;
                if (res.status == 200) {
                    if (!!res.data && res.data.status === "success") {
                        // sessionStorage.setItem('session',JSON.stringify(values));
                        sessionStorage.setItem('session_name', res.data.response.username);
                        sessionStorage.setItem('session_role',  res.data.response.role);
                        sessionStorage.setItem('access_token', res.data.response.accesstoken)
                        history.push("/home");
                    }
                } else {
                    setLoading(false);
                    setOpen(true);
                    setSeverity('error')
                    setMsg(res.msg);
                }
        }).catch(function(error) {
            setLoading(false);
           if(!!error && !!error.response){
            setOpen(true);
            setSeverity('error')
            setMsg(error.response.data.msg);
           }
          });
    }
    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
        setOpen(false);
      };
    return (
        <>
        <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
                <Alert onClose={handleClose} severity={severity}>
                    {msg}
              </Alert>
            </Snackbar>
        <Grid>
            <Paper elevation={10} style={{ padding: '20px', height: '70vh', width: 280, margin: "70px auto" }}>
                <div align="center"><img src="/logo-main-black.png" style={{width:'50%'}}/></div>
                <Grid align='center'>
                    <Avatar style={{backgroundColor:'#1bbd7e'}}>
                        <LockOutlinedIcon />
                    </Avatar>
                    <h2>Sign In</h2>
                </Grid>
                <form onSubmit={handleSubmit(onSubmit)}>
                <Controller
                  control={control}
                  name="username"
                  render={({ field }) => (
                    <TextField {...field} placeholder='Enter Email' label="User Email" fullWidth/>
                  )}
                    />
                    <Controller
                  control={control} fullWidth
                  name="password"
                  render={({ field }) => (
                    <TextField {...field} placeholder='Enter password' type='password' label="Password" fullWidth/>
                  )}
                />

{
                loading ?  
                <ClipLoader
                    color={color}
                    loading={loading}
                    size={50}
                    cssOverride= { {display: "block",   margin: '0 auto'} }
                />: 

                 <Button type='submit' color='primary' variant="contained" style={{ margin: '28px 0' }} fullWidth >Sign in</Button>}

               
                </form>
                </Paper>
        </Grid>
        </>
    )
}

export default Login;