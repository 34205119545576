import React from 'react';
import {  BrowserRouter as Router } from "react-router-dom";
import { Route, Switch } from "react-router-dom";
import { makeStyles, CssBaseline, createTheme, ThemeProvider } from '@material-ui/core/styles';

import './App.css';

import Login from './pages/Login';
import Home from './pages/Home';
import SLogs from './pages/SLogs';
import Order from './pages/Order';
import Vieworder from './pages/Vieworder'
import Ingramorder from './pages/Ingramorder'
import Product from './pages/Product'
const theme = createTheme({
  palette: {
    primary: {
      main: "#333996",
      light: '#3c44b126'
    },
    secondary: {
      main: "#f83245",
      light: '#f8324526'
    },
    background: {
      default: "#f4f5fd"
    },
  },
  overrides: {
    MuiAppBar: {
      root: {
        transform: 'translateZ(0)'
      }
    }
  },
  props: {
    MuiIconButton: {
      disableRipple: true
    }
  }
});


const useStyles = makeStyles({
  appMain: {
    paddingLeft: '320px',
    width: '100%'
  }
});

function App() {
  const classes = useStyles();
 
  return (
    <ThemeProvider theme={theme}>
      <Router>
        <Switch>
          <Route exact path="/">
            <Login />
          </Route>
            <Route exact path="/home">
              <Home />
            </Route>
            <Route exact path="/slogs">
              <SLogs />
            </Route>
            <Route exact path="/pcorder">
              <Order />
            </Route>
            <Route exact path="/viewpo">
              <Vieworder />
            </Route>
            <Route exact path="/viewingram">
              <Ingramorder />
            </Route>
            <Route exact path="/addproduct">
              <Product />
            </Route>
        </Switch>
      </Router>
    </ThemeProvider>
   );
}

export default App;
