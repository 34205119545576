import React, { useState, useEffect }  from 'react'
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew';
import { useHistory } from "react-router-dom"


const useStyles = makeStyles(theme => ({
   root: {
     flexGrow: 1,
   },
   menuButton: {
     marginRight: theme.spacing(2),
   },
   title: {
     flexGrow: 1,
     '&:hover': {
        cursor: "pointer"
      }
    },
    menuName: {
        fontSize: '14px',
        '&:hover': {
            cursor: "pointer"
          }
    },
   buttonIcon: {
       color: theme.palette.common.white,
   },
   logoutbutton:{
    position: 'relative',
    marginLeft: 0,
   },
   
}))

export default function Header() {
    let history = useHistory()
    const classes = useStyles();

    const [userrole,setUserrole] = useState([""]);

    useEffect(() => {
     let role =  sessionStorage.getItem('session_role');
     setUserrole(role)
              }, []);
  

              

    return (
        <div className={classes.root}>
            <AppBar position="static">
                <Toolbar>
                   
                <Typography className={classes.title} variant="h6" noWrap>
                  <img src="/logo-main-white.png" onClick={() => { history.push("/home"); }}/>
                </Typography>
               
                    <Typography className={`${classes.menuName} ${classes.menuButton}` }  variant="h6" color="inherit"  component="div" onClick={() => { history.push("/home"); }}>
                      Stock
                    </Typography>
                    {
                  userrole == "ingram" ? (

                    <Typography className={`${classes.menuName} ${classes.menuButton}` }  variant="h6" color="inherit"  component="div" onClick={() => { history.push("/addproduct"); }}>
                      Add Product
                    </Typography>
                   ) : ("")
                  } 
                {
                  userrole == "ingram" ? (
          
                    <Typography className={`${classes.menuName} ${classes.menuButton}` }  variant="h6" color="inherit"  component="div" onClick={() => { history.push("/pcorder"); }}>
                      Create PO
                    </Typography>
                ) : ("")
                }

                  {
                  userrole == "ingram" ? (
          
                    <Typography className={`${classes.menuName} ${classes.menuButton}` }  variant="h6" color="inherit"  component="div" onClick={() => { history.push("/viewpo"); }}>
                      View PO
                    </Typography>
                      ) : ("")
                    }

                {
                  userrole == "ingram" ? (
                    <Typography className={`${classes.menuName} ${classes.menuButton}` }  variant="h6" color="inherit"  component="div" onClick={() => { history.push("/viewingram"); }}>
                      Ingram Order
                    </Typography>
                  ) : ("")
                }
              
               {
                  userrole == "ingram" ? (
                <Typography className={`${classes.menuName} ${classes.menuButton}`} variant="h6" color="inherit" component="div" onClick={() => { history.push("/slogs"); }}>
                        Scheduler Log
                  </Typography>
                   ) : ("")
                  }

                    {/* <Typography  noWrap>
                    <img src="/logo-main-white.png" onClick={() => { history.push("/home"); }}/>
                    </Typography> */}

                    {/* <Typography className={classes.menuName}  variant="h6" color="inherit"  component="div" onClick={() => { history.push("/slogs"); }}>
                        Purchase Order
                    </Typography>
                    

                    <Typography justify="flex-end" className={classes.menuName} variant="h6" color="inherit" component="div" onClick={() => { history.push("/slogs"); }}>
                         Scheduler Log
                    </Typography> */}
                    <div className={classes.logoutbutton}>
                      <IconButton className={classes.logoutbutton} onClick={() => { history.push("/") }}>
                          <PowerSettingsNewIcon fontSize="small" className={classes.buttonIcon} />
                      </IconButton>
                    </div>
                    
                </Toolbar>
            </AppBar>
        </div>
    )
}