import React from 'react'
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import Controls from "../components/controls/Controls";
import moment from "moment";
export const ExportToExcel = ({ btnColor="primary", apiData, fileName , heading, displayTxt,sheetName}) => {
  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";

    const exportToCSV = (apiData, fileName, heading, sheetName) => {
        var wb = XLSX.utils.book_new();
        const ws = XLSX.utils.json_to_sheet(apiData, { origin: 'A2', skipHeader: true });
        XLSX.utils.sheet_add_aoa(ws, heading); //heading: array of arrays
        XLSX.utils.book_append_sheet(wb, ws,sheetName );
        // const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
        const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
        const data = new Blob([excelBuffer], { type: fileType });
        const newFilename = fileName + '_' + moment().format("YYYYMMDD_hhmmss");
        FileSaver.saveAs(data,  newFilename+ fileExtension);
    };

    return (
      <Controls.Button
      color={btnColor}
      text={displayTxt}
      onClick={(e) => exportToCSV(apiData, fileName, heading, sheetName)}
      />
      );
};