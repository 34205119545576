import React, { useState, useEffect, useCallback,CSSProperties } from 'react'
import PeopleOutlineTwoToneIcon from '@material-ui/icons/PeopleOutlineTwoTone';
import { Paper, makeStyles, TableBody, TableRow, TableCell, Toolbar, InputAdornment } from '@material-ui/core';
import useTable from "../components/useTable";
import * as inventoryService from "../services/inventoryService";
import Controls from "../components/controls/Controls";
import IOSSwitch from "../components/controls/IOSSwitch";
import { Search } from "@material-ui/icons";
import Header from "../components/header";
import { ExportToExcel } from '../components/ExportToExcel';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import ClipLoader from "react-spinners/ClipLoader";
import DeleteIcon from '@material-ui/icons/Delete';
import Modal from '@material-ui/core/Modal';
import { useHistory } from "react-router-dom"

function rand() {
    return Math.round(Math.random() * 20) - 10;
  }
  
  function getModalStyle() {
    const top = 50 + rand();
    const left = 50;
  
    return {
      top: `${top}%`,
      left: `${left}%`,
      transform: `translate(-${top}%, -${left}%)`,
    };
  }

const useStyles = makeStyles(theme => ({
    root: {
      width: '100%',
      '& > * + *': {
        marginTop: theme.spacing(2),
      },
    },pageContent: {
        marginTop: theme.spacing(5),
        padding: theme.spacing(3)
    },
    searchInput: {
        width: '50%'
    },
    spaceSelect: {
        margin: '20px'
    },
    handPointer :{
        cursor: 'pointer'
    },
    paper: {
        position: 'absolute',
        width: 400,
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
      }
}));


function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

const headCells_admin = [
    { id: 'cat_name', label: 'Category' },
    { id: 'prod_name', label: 'Model' },
    { id: 'psn', label: 'APX ItemCode' },
    { id: 'ext_prod_id', label: 'Ingram Partnumber' },
    { id: 'sku', label: 'Manufacturer Partnumber' },
    { id: 'qty_on_hand', label: 'Available Quantity' }
];
const headCells_ingram = [
    { id: 'cat_name', label: 'Category' },
    { id: 'prod_name', label: 'Model' },
    { id: 'psn', label: 'APX ItemCode' },
    { id: 'ext_prod_id', label: 'Ingram Partnumber' },
    { id: 'sku', label: 'Manufacturer Partnumber' },
    { id: 'qty_on_hand', label: 'Available Quantity' },
    { id: 'isactive', label: 'Status' },
    { id: '', label: 'Action' }
];

const Heading = [
    {
        t: 's',
        v: 'Category'
    },{
        t: 's',
        v: 'Model'
    },{
        t: 's',
        v:  'APX ItemCode'},
    {
        t: 's',
        v: 'Ingram Partnumber'
    }, {
        t: 's',
        v: 'Manufacturer Partnumber'
    }, {
        t: 's',
        v: 'Available Quantity'
    },{
        t: 's',
        v: 'Status'
    }
];
let MyHeading = [];
MyHeading[0] = Heading;

export default function Home() {
    let history = useHistory(); 
    const classes = useStyles();
    const [records, setRecords] = useState([]);
    const [selected, setSelected] = useState('');
    // const [filterFn, setFilterFn] = useState({ fn: items => { return items; } });
    const fileName = "Inventory_Stock"; // here enter filename for your excel file
    const [searchTxt, setSearchTxt] = useState("");
    const [stockSelValue, setStockSelValue] = useState("all");
    const [open, setOpen] = React.useState(false);
    const [severity, setSeverity] = React.useState("success");
    const [msg, setMsg] =  React.useState("");
    let [color, setColor] = useState("#226cd7");
    let [loading, setLoading] = useState(false);
    const [userrole,setUserrole] = useState([""]);
    const [modalOpen, setModalOpen] = useState(false);
    const [modalStyle] = React.useState(getModalStyle);

    const [selectedIdxDel, setSelectedIdxDel]=  useState(-1);
    const [selectedIdxDelName, setSelectedIdxDelName]=  useState(-1);
    useEffect(() => {
     let role =  sessionStorage.getItem('session_role');
     setUserrole(role)
              }, []);

    const handleStatusChange = useCallback((event) => {
        let selectedName = event.target.name;
        const selectedPSN = selectedName.replace('status', '');
        const selectedIdx  = records.findIndex((prod) => prod['psn'] ===  selectedPSN)
        records[selectedIdx]['isactive'] = !records[selectedIdx]['isactive'];
        setRecords(records.slice(0));
        setSelected(records[selectedIdx]);
    }, [records, selected]);

    useEffect(() => {
        if (!!selected) {
            let statusMsg = !!selected['isactive'] ? 'Active' : 'Inactive';
            const requestOptions = {
                method: 'PUT',
                headers: { 'Content-Type': 'application/json',  'authorization' :  sessionStorage.getItem('access_token') }
            };
            fetch(process.env.REACT_APP_API_PREFIX_URL + 'inventory/' + selected['psn'] + '/' +  selected['ext_prod_id']+ '/' + selected['isactive'], requestOptions)
                .then(response => response.json())
                .then(result => {
                    if(result.status === "Failed" && result.message === "Invalid token"){
                        history.push("/");
                    } else{
                        setOpen(true);
                        setSeverity('success')
                        setMsg("Product(" + selected['psn'] + ") status updated to "+ statusMsg);
                        setSelected('');
                    }
                })
                .catch(error => {
                    setOpen(true);
                    setSeverity('error');
                    setMsg("Product(" + selected['psn'] + ") status not updated to "+ statusMsg);
                    console.log(error);
                })
        }
    }, [selected ,setSelected]);


    useEffect(() => {
        setLoading(true);
        const requestOptions = {
            method: 'GET',
            headers: { 'Content-Type': 'application/json', 'authorization' :  sessionStorage.getItem('access_token') }
        };
        fetch(process.env.REACT_APP_API_PREFIX_URL + 'inventory/report/stockdetail?search='+searchTxt+'&stock='+stockSelValue, requestOptions)
          .then(response => response.json())
          .then(result => {
            if(result.status === "Failed" && result.message === "Invalid token"){
                setLoading(false);
                setRecords([]);
                history.push("/");
            } else if( result.status == "Failed" ){
                setLoading(false);
                setRecords([])
            } else{
                setRecords(result["response"])
                setLoading(false); 
            }
           
          })
            .catch(error => {
                console.log(error);
                setLoading(false);
                setRecords([])
            })
    }, [searchTxt, stockSelValue ,setRecords]);

    const headCells  = userrole == "ingram" ? headCells_ingram : headCells_admin;
    const {
        TblContainer,
        TblHead,
        TblPagination,
        recordsAfterPagingAndSorting
    } = useTable(records, headCells);

    const triggerSearch = (searchVal) => {
        setSearchTxt(searchVal);
        // console.log("searchText", searchVal);
    }

    const handleInputChange = (evt) => {
        setStockSelValue(evt.target.value);
    }

    const confirmDeleteAction = (evt) =>{
        let getSelected = records[selectedIdxDel];
        const requestOptions = {
            method: 'DELETE',
            headers: { 'Content-Type': 'application/json', 'authorization' :  sessionStorage.getItem('access_token') },
            body: JSON.stringify({ sku:   getSelected['sku']})

        };
        fetch(process.env.REACT_APP_API_PREFIX_URL + 'inventory/' + getSelected['psn'] + '/' +  getSelected['ext_prod_id'], requestOptions)
            .then(response => response.json())
            .then(result => {
                if(result.status === "Failed" && result.message === "Invalid token"){
                    setLoading(false);
                    setRecords([]);
                    history.push("/");
                } else{
                    const newProducts = [...records];
                    newProducts.splice(selectedIdxDel, 1);
                    setRecords(newProducts);
                    setOpen(true);
                    setSeverity('success')
                    setMsg("Product(" + getSelected['prod_name'] + ") is deleted.");
                    setSelected('');
                    setModalOpen(false);
                    setSelectedIdxDelName("");
                    setSelectedIdxDel(-1);
                }
            })
            .catch(error => {
                setOpen(true);
                setSeverity('error');
                setMsg("Product(" + getSelected['prod_name'] + ") is not deleted.");
                console.log(error);
                setModalOpen(false);
                setSelectedIdxDelName("");
                setSelectedIdxDel(-1);
            })

    }

    const handleCloseModal = () => {
        setModalOpen(false);
        setSelectedIdxDelName("");
        setSelectedIdxDel(-1);
    };


    const handleOpenModal = (idx, prod_name) =>{
        setSelectedIdxDelName(prod_name);
        setSelectedIdxDel(idx);
        setModalOpen(true);
    }


    const body = (
        <div style={modalStyle} className={classes.paper}>
          <h2 id="simple-modal-title">Confirmation Dialog for Delete Product</h2>
          <p id="simple-modal-description">
            Do you want to Delete &nbsp;"{selectedIdxDelName}"?
          </p>
          <div>
            <Controls.Button  
                size = "small"
                text="Yes, Delete"
                onClick={(evt) => confirmDeleteAction() } />
            <Controls.Button  
                size = "small"
                color ="secondary"
                text="No"
                onClick={(evt) => handleCloseModal() } />
          </div>
        </div>
      );

    
    const handleClose = (event, reason) => {
      if (reason === 'clickaway') {
        return;
      }

      setOpen(false);
    };

    return (
        <div>
            <Header />
            <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
                <Alert onClose={handleClose} severity={severity}>
                    {msg}
              </Alert>
            </Snackbar>
            <div >
                <Toolbar className={classes.pageContent}>
                    <Controls.Input
                        label="Search Products"
                        className={classes.searchInput}
                        InputProps={{
                            startAdornment: (<InputAdornment position="start">
                                <Search />
                            </InputAdornment>)
                        }}
                        onKeyPress={(ev) => {
                        //   console.log(`Pressed keyCode ${ev.key}`);
                          if (ev.key === 'Enter') {
                              triggerSearch(ev.target.value);
                            ev.preventDefault();
                          }
                        }}
                    />
                    <div className = {classes.spaceSelect}>
                    <Controls.Select
                        name="stockId"
                        value={stockSelValue}
                        onChange={handleInputChange}
                        options={[{id:"all", title: "Select All"},{id:"instock", title: "In Stock"},{id:"outofstock", title: "Out Of Stock"}]}
                        key_id ="id"
                        key_title ="title"
                    />
                    </div>
                    <ExportToExcel apiData={records} fileName={fileName} heading={MyHeading} displayTxt={"Export Data"} sheetName={"Inventrory_Stock"}/>
                </Toolbar>

                { loading ? 
                    <div>
                    <ClipLoader
                        color={color}
                        loading={loading}
                        size={50}
                        cssOverride= { {display: "block",   margin: '0 auto'} }
                    /> </div>:
                
                        <div>
                            <TblContainer>
                                <TblHead />

                            
                            
                
                                    <TableBody>
                                        {
                                            recordsAfterPagingAndSorting().map((prod, index) =>
                                                (<TableRow key={index}>
                                                    <TableCell>{prod.cat_name}</TableCell>
                                                    <TableCell>{prod.prod_name}</TableCell>
                                                    <TableCell>{prod.psn}</TableCell>
                                                    <TableCell>{prod.ext_prod_id}</TableCell>
                                                    <TableCell>{prod.sku}</TableCell>
                                                    <TableCell>{prod.qty_on_hand}</TableCell>
                                                
                                                {
                                                    userrole == "ingram" ? 
                                                    <>
                                                        <TableCell>
                                                            <IOSSwitch
                                                                itemvalue={prod}
                                                                name={'status' + prod.psn}
                                                                checked={prod.isactive}
                                                                handleChange={handleStatusChange}
                                                                disablebutton = {userrole == "ingram" ? false : true}
                                                            />
                                                        </TableCell>
                                                        <TableCell>
                                                            <DeleteIcon  name={'delete' + prod.psn} className={classes.handPointer} color="secondary" onClick={() =>  handleOpenModal(index, prod.prod_name) }/>
                                                        </TableCell>
                                                    </>
                                                    
                                                    : ("")
                                                }
                                                
                                                </TableRow>)
                                            )
                                        }
                                    </TableBody>
                                
                                
                            </TblContainer>
                            <TblPagination />
                        </div>
}
            </div>
            <Modal
            open={modalOpen}
            onClose={handleCloseModal}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
        >
          {body}
        </Modal>
        </div>
    )
}