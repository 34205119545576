import React, { useState, useEffect,Fragment } from 'react'
import { makeStyles, TableBody, TableRow, TableCell, Toolbar } from '@material-ui/core';
import useTable from "../components/useTable";
import Header from "../components/header";
import { DatePicker } from "@material-ui/pickers";
import Typography from '@material-ui/core/Typography';
import Controls from "../components/controls/Controls";
import ClipLoader from "react-spinners/ClipLoader";
import { useHistory } from "react-router-dom"

const useStyles = makeStyles(theme => ({
    root: {
      width: '100%',
      '& > * + *': {
        marginTop: theme.spacing(2),
      },
    },pageContent: {
        marginTop: theme.spacing(5),
        padding: theme.spacing(3)
    },
    searchInput: {
        width: '50%'
    },
    spaceSelect: {
        margin: '20px'
    },
    pullright: {
        position: 'absolute',
        right: 10,
        fontWeight: 'bold'
    },
    title: {
        margin: '20px',
        color: '#1a1ab7'
    },
    success : {
        color: 'green'
    },
    failed : {
        color: 'red'
    }
}));

const headCells = [
    { id: 'id', label: 'Ref ID' },
    { id: 'apx_po', label: 'APX PO NO' },
    { id: 'global_order_id', label: 'Global Order Id' },
    { id: 'global_order_status', label: 'Global Order Status' },
    { id: 'apx_po_date', label: 'Order Created On' },
    { id: 'branch_code', label: 'Branch Code' } ,  
    { id: 'Action', label: 'Action' }   
];

const Heading = [
    {
        t: 's',
        v: 'Ref ID'
    },{
        t: 's',
        v: 'APX PO NO'
    },{
        t: 's',
        v: 'Global Order Id'
    },{
        t: 's',
        v:  'Job End Date'},
    {
        t: 's',
        v: 'Global Order Status'
    }, {
        t: 's',
        v: 'Order Created On'
    }, {
        t: 's',
        v: 'Branch Code'
    }
];
let MyHeading = [];
MyHeading[0] = Heading;

export default function Ingramorder() {
    let history = useHistory(); 

    const classes = useStyles();
    const [records, setRecords] = useState([]);

    let [color, setColor] = useState("#226cd7");
    let [loading, setLoading] = useState(false);

    // const [filterFn, setFilterFn] = useState({ fn: items => { return items; } });
    const [selectedStartDate, setSelectedStartDate] = useState(
         () => {
            const datet = new Date();
            const previous = new Date(datet.getTime());
            previous.setDate(datet.getDate() - 1);

            let month = previous.getMonth() +1;
            let date = previous.getDate();
            if(date < 10){
                date = "0"+date;
            }
            if(month < 10){
                month = "0"+month;
            }
            return new Date().getFullYear() +"-" + month + "-" + date;

        // let d = new Date();
        // // let month = d.getMonth();
        // // d.setMonth(d.getMonth() - 1);
        // // while (d.getMonth() === month) {
        // //     d.setDate(d.getDate() - 1);
        // // }
        // // let date = d.getDate();
        // if(date < 10){
        //     date = "0"+date;
        // }
        // if(month < 10){
        //     month = "0"+month;
        // }
        // return new Date().getFullYear() +"-" + month + "-" + date;

    }

    );
    const [selectedEndDate, setSelectedEndDate] = useState(
            () => {

                let month = new Date().getMonth()+1;
                let date = new Date().getDate();
        
                if(date < 10){
                    date = "0"+date;
                }
                if(month < 10){
                    month = "0"+month;
                }
                return new Date().getFullYear() +"-" + month + "-" + date;
        
            }
    
    );

    useEffect(() => {
        // console.log(selectedStartDate,"start");
        // console.log(selectedEndDate,"end");
        setLoading(true);
        const requestOptions = {
            method: 'GET',
            headers: { 'Content-Type': 'application/json', 'authorization' :  sessionStorage.getItem('access_token') }
        };
        fetch(process.env.REACT_APP_API_PREFIX_URL + 'porder/listingram', requestOptions)
          .then(response => response.json())
          .then(result => {
            if(result.status === "Failed" && result.message === "Invalid token"){
                history.push("/");
            }else{
                setRecords(result["response"]);
                setLoading(false);
            }
          })
            .catch(error => {
                console.log(error);
                setRecords([])
                setLoading(false);
            })
    }, [selectedStartDate, selectedEndDate]);

    const {
        TblContainer,
        TblHead,
        TblPagination,
        recordsAfterPagingAndSorting,
        getTotalRecords,
        getTotalSuccessecords,
        getTotalFailedecords
    } = useTable(records, headCells);


    const handleDateChange1 = (sdate) => {
        let month = sdate.getMonth()+1;
        let date = sdate.getDate();

        if(date < 10){
            date = "0"+date;
        }
        if(month < 10){
            month = "0"+month;
        }
        setSelectedStartDate( sdate.getFullYear() +"-" + month + "-" + date);  
    }

    const handleDateChange2 = (edate) => {
        let month = edate.getMonth()+1;
        let date = edate.getDate();

        if(date < 10){
            date = "0"+date;
        }
        if(month < 10){
            month = "0"+month;
        }
        setSelectedEndDate( edate.getFullYear() +"-" + month + "-" + date);  
    }

    const checkCurrentStatus = (evt, order, id) =>{
        //porder/checkstatusingram?ingram_globalOrderid="+order.global_order_id
        const requestOptions = {
            method: 'GET',
            headers: { 'Content-Type': 'application/json', 'authorization' :  sessionStorage.getItem('access_token') }
        };
        fetch(process.env.REACT_APP_API_PREFIX_URL + 'porder/checkstatusingram?ingram_globalOrderid='+order.global_order_id, requestOptions)
        .then(response => response.json())
        .then(result => {
            if(result.status === "Failed" && result.message === "Invalid token"){
                history.push("/");
            }else{
                let updateddata = order;
                
                updateddata["global_order_status"] = result["response"];

                let curList = [...records];
                curList[id] = updateddata;
                setRecords(curList);
            }
        })
            .catch(error => {
                console.log(error);
                // setStock([])
            })
    }

    return (
        <div>
            <Header />

            <div >
                <div>
                    <Typography variant="h6" className={classes.title}>
                    Ingram Gloabl Orders
                    </Typography>
                </div>
            {/* <Toolbar> */}
            {/* <Fragment>
            <DatePicker 
             label="Start Date"
            value={selectedStartDate} 
            onChange={handleDateChange1} 
            format="yyyy-MM-dd"
            />
            <div className = {classes.spaceSelect}></div>
            <DatePicker 
             label="End Date"
            value={selectedEndDate} 
            onChange={ handleDateChange2} 
            format="yyyy-MM-dd"
               />
              </Fragment> */}
              {/* <div className = {classes.spaceSelect}></div>
              <div className = {classes.spaceSelect}></div> */}
              {/* <Typography> 
                Total Order : &nbsp;{getTotalRecords()}&nbsp; <br/> 
            </Typography> */}
            {/* <div className = {classes.spaceSelect}></div> */}
            {/* <div variant="h6" align="right" className={classes.pullright}> Total &nbsp;{getTotalRecords()}&nbsp;
             no of records found. </div> */}
           
            {/* </Toolbar> */}

            { loading ? 
                    <div>
                    <ClipLoader
                        color={color}
                        loading={loading}
                        size={50}
                        cssOverride= { {display: "block",   margin: '0 auto'} }
                    /> </div>:
                    <div>
                    <TblContainer>
                        <TblHead />
                        <TableBody>
                            {
                                recordsAfterPagingAndSorting().map((order, index) =>
                                    (<TableRow key={index}>
                                        <TableCell>{order.id}</TableCell>
                                        <TableCell>{order.apx_po}</TableCell>
                                        <TableCell>{order.global_order_id}</TableCell>
                                        <TableCell>{order.global_order_status}</TableCell>
                                        <TableCell>{order.apx_po_date}</TableCell>
                                        <TableCell>{order.branch_code}</TableCell>
                                        <TableCell>
                                        <Controls.Button
                                            size = "small"
                                            text="Check Status"
                                            onClick={(evt) => checkCurrentStatus(evt, order, index)}
                                        /> 
                                        </TableCell>
                                    </TableRow>)
                                )
                            }
                        </TableBody>
                    </TblContainer>
                    <TblPagination />
                </div>
}
            </div>
        </div>
    )
}