import React, { useState, useEffect, useCallback, useRef } from 'react'
import { makeStyles, TableBody, TableRow, TableCell,Table, TableSortLabel, TableHead } from '@material-ui/core';
import Header from "../components/header";
import Typography from '@material-ui/core/Typography';
import Controls from "../components/controls/Controls";
import Grid from '@material-ui/core/Grid';
import ClipLoader from "react-spinners/ClipLoader";
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { ExportToExcel } from '../components/ExportToExcel';
import { useHistory } from "react-router-dom"



const useStyles = makeStyles(theme => ({
    root: {
      width: '100%',
      '& > * + *': {
        marginTop: theme.spacing(2),
      },
    },pageContent: {
        marginTop: theme.spacing(5),
        padding: theme.spacing(3)
    },
    searchInput: {
        width: '50%'
    },
    spaceSelect: {
        margin: '0px'
    },
    spaceSelectTop: {
        marginTop: '10px'
    },
    spaceSelectBt: {
        marginBottom: '5px'
    },
    spaceSelectReq: {
        marginLeft: '12px'
    },
    selectWidth:{
        width: '100px'
    },success : {
        color: 'green'
    },
    failed : {
        color: 'red'
    },
    addbutton:{
        width: '100px',
        marginTop: '11px'
    },
    alignbutton : {
        textAlign:'center',
        marginTop: '15px'
    },
    bigFont: {
        fontSize: '22px'
    },
    boldnumber:{
        fontSize: '1.25rem',
        fontWeight: 900,
        lineHeight: 1.6,
        letterSpacing: '0.0075em'

    },
    grandRow:{
        textAlign: 'right',
        marginRight: '10rem'
    }, 
    visiblity_flag:{
        visibility: 'hidden'
    }
}));

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }
let MyHeading = [];
MyHeading[0] = [{
    t: 's',
    v: 'APX_ItemCode'
},{
    t: 's',
    v: 'Ingram_Partnumber'
},{
    t: 's',
    v:  'Product_Name'
},{
    t: 's',
    v: 'Category'
},{
    t: 's',
    v: 'Manufacturer_Partnumber'
}];

let MyHeading_Error = [];
MyHeading_Error[0] = [{
    t: 's',
    v: 'APX_ItemCode'
},{
    t: 's',
    v: 'Ingram_Partnumber'
},{
    t: 's',
    v:  'Product_Name'
},{
    t: 's',
    v: 'Category'
},{
    t: 's',
    v: 'Manufacturer_Partnumber'
},{
    t: 's',
    v: 'Error_MSG'
}];

const Product = () => {
    const classes = useStyles();
    let history = useHistory(); 
    const [vendorList,setVendorList] = useState([{seller_code:"100001", seller_name: "Ingram Micro India Private Limited"}]);
    const [vendorCode,setVendorCode] = useState(vendorList[0]["seller_code"]);
    const [branchlist,setBranchlist] = useState([{id:"-1", title: "Select Branch"}]);
    const [branchcode, setBranchcode] = useState("-1");
    const [branchdetail, setBranchdetails] = useState({});
    const [apxitemcode, setApxitemcode] = useState("");
    const [globalProductId, setGlobalProductId] = useState("");
    const [productname, setProductname] = useState("");
    const [listOfProductAddOption, setListOfProductAddOption] =  useState([{id:"multi", title:"Multiple Product"},{id:"single", title:"Single Product"}]);
    const [selectedAddProduct, setSelectedAddProduct] = useState("multi");
    const [errordetail, setErrorDetail] = useState([]);
    const [catagotyList,setCatagotyList] = useState([{cat_id:"1", cat_name: "M-Accessories"},{cat_id:"2", cat_name: "A-Acc Pad"},{cat_id:"3", cat_name: "G-Boom Headset"},{cat_id:"5", cat_name: "M-Mac"},
    {cat_id:"6", cat_name: "M-Acc Mac"},
    {cat_id:"7", cat_name: "G-Smart Tracker"},
    {cat_id:"8", cat_name: "A-Charger"},
    {cat_id:"9", cat_name: "G-Smart Watch"},
    {cat_id:"10", cat_name: "M-Acc Phone"},
    {cat_id:"11", cat_name: "G-IOT Smart"},
    {cat_id:"12", cat_name: "A-Connector"},
    {cat_id:"13", cat_name: "A-Back Case"},
    {cat_id:"14", cat_name: "G-BT Speaker"},
    {cat_id:"15", cat_name: "M-Apple Watch Band"},
    {cat_id:"16", cat_name: "A-Acc Mac"},
    {cat_id:"17", cat_name: "M-Acc Pad"},
    {cat_id:"18", cat_name: "M-Mobile"},
    {cat_id:"19", cat_name: "G-Smart Tracker Acc"},
    {cat_id:"20", cat_name: "M-Bluetooth"},
    {cat_id:"21", cat_name: "A-Data Cable"},
    {cat_id:"22", cat_name: "A-Head Phone"},
    {cat_id:"23", cat_name: "M-true Wireless (TWS)"},
    {cat_id:"24", cat_name: "M-PAD"},
    {cat_id:"25", cat_name: "Other"},
    {cat_id:"26", cat_name: "C-Power Adapter"},
    {cat_id:"27", cat_name: "A-Stylus Pen/Pencil"},
    {cat_id:"28", cat_name: "A-Cable"},
    {cat_id:"29", cat_name: "A-Pouch"},
    {cat_id:"30", cat_name: "A-Watch Strap"},
    {cat_id:"31", cat_name: "C-Cable"},
    {cat_id:"32", cat_name: "C-Connector"},
    {cat_id:"33", cat_name: "C-KeyBoard"},
    {cat_id:"34", cat_name: "C-Mouse"}
]);
    const [catCode, setCatCode] = useState(catagotyList[0]["cat_id"]);
    const [manufacturerList,setManufacturerList] = useState([{id:"1", name: "Apple"},{id:"2", name: "Other"}]);
    const [manufacturerId,setManufacturerId] = useState(manufacturerList[0]["id"]);
    const [brandList,setBrandList] = useState([{id:"1", name: "Apple"},{id:"2", name: "Other"}]);
    const [brandId,setBrandId] = useState(brandList[0]["id"]);
    const [skuid, setSkuid] = useState("");
    let [color, setColor] = useState("#226cd7");
    let [loading, setLoading] = useState(false);
    let [bdloading, setBdloading] = useState(false);
    let [createOrderloading, setCreateOrderloading] = useState(false);
    const fileName = "Sample_Product_Template"; // here enter filename for your excel file
    const fileName_error = "Detail_error_desc";
    
    const [open, setOpen] = React.useState(false);
    const [severity, setSeverity] = React.useState("success");
    const [msg, setMsg] =  React.useState("");

    const handleInputChangeVendor = (evt) => {
        setVendorCode(evt.target.value);
    }

    const handleInputChange = (evt) => {
        setBranchcode(evt.target.value);
    }

    const handleItemcodeChange = (evt) =>{
        setApxitemcode(evt.target.value);
    }

    const handleGlobalProductChange = (evt) =>{
        setGlobalProductId(evt.target.value);
    }

    const handleProductnameChange = (evt) =>{
        setProductname(evt.target.value);
    }

    const handleProductAddOptionChange = (evt) =>{
        setSelectedAddProduct(evt.target.value);
    }

    const handleInputChangeCat = (evt) =>{
        setCatCode(evt.target.value)
    }
    
    const handleInputChangeManufacture = (evt) =>{
        setManufacturerId(evt.target.value)
    }

    const handleInputChangeBrand = (evt) =>{
        setBrandId(evt.target.value)
    }

    const handleInputChangeSku = (evt) =>{
        setSkuid(evt.target.value)
    }

    const addProduct = (evt) =>{
        debugger;
        if(!apxitemcode){
            setOpen(true);
            setSeverity('warning')
            setMsg("Please Provice APX Item Code.");
            return false;
        }

        if(!globalProductId){
            setOpen(true);
            setSeverity('warning')
            setMsg("Please Provice Global Product Id.");
            return false;
        }

        if(!productname){
            setOpen(true);
            setSeverity('warning')
            setMsg("Please Provice Product Name.");
            return false;
        }

        if(!skuid){
            setOpen(true);
            setSeverity('warning')
            setMsg("Please Provice Brand SKU ID.");
            return false;
        }

        const catObjectIdx =catagotyList.findIndex(x => x.cat_id === catCode);
        const manufacturerIdx =manufacturerList.findIndex(x => x.id === manufacturerId);
        const brandIdx =brandList.findIndex(x => x.id === brandId);

        let addProductObj = {
            "virtua_branch_code":branchdetail["virtual_branch_code"],
            "virtual_branch_name":branchdetail["virtual_branch_name"],
            "seller_code": branchdetail["seller_code"],
            "apx_item_code":apxitemcode,
            "global_product_id":globalProductId,
            "product_name":productname,
            "sku_id": skuid,
            "category":catagotyList[catObjectIdx]["cat_name"],
            "manufacture_name": manufacturerList[manufacturerIdx]["name"],
            "brand_name": brandList[brandIdx]["name"]
    }
    console.log("addProductObj",addProductObj)

    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'authorization' :  sessionStorage.getItem('access_token') },
        body: JSON.stringify(addProductObj)
    };

    setCreateOrderloading(true);

    fetch(process.env.REACT_APP_API_PREFIX_URL + 'inventory/addnewproduct', requestOptions)
    .then(response => response.json())
    .then(result => {
        if(result.status === "Failed" && result.message === "Invalid token"){
            history.push("/");
        }else if(result.status == "success") {
            setOpen(true);
            setSeverity('success')
            setMsg(result.msg);
            setApxitemcode("");
            setGlobalProductId("");
            setSkuid("");
            setProductname("");
            setCatCode(catagotyList[0]["cat_id"]);
            setCreateOrderloading(false);
        } else {
            setOpen(true);
            setSeverity('error')
            setMsg(result.msg);
            setCreateOrderloading(false);
        }
     })
        .catch(error => {
            console.log(error);
            setCreateOrderloading(false);
         })

}
    const cancelAddProduct = (evt) =>{
       setApxitemcode("");
       setGlobalProductId("");
       setSkuid("");
       setProductname("");
       setCatCode(catagotyList[0]["cat_id"]);
       setCreateOrderloading(false);
    }

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
        setOpen(false);
      };




    useEffect(() => {
        setLoading(true);
        const requestOptions = {
            method: 'GET',
            headers: { 'Content-Type': 'application/json', 'authorization' :  sessionStorage.getItem('access_token') }
        };
        fetch(process.env.REACT_APP_API_PREFIX_URL + 'branchdetail/list', requestOptions)
            .then(response => response.json())
            .then(result => {
                if(result.status === "Failed" && result.message === "Invalid token"){
                    history.push("/");
                }else{
                    setBranchlist(result["response"]);
                    if(result["response"].length > 1){
                        setBranchcode(result["response"][1]["id"])
                    }
                    // setBranchcode()
                    setLoading(false);
                }
            }).catch(error => {
                            console.log(error);
                            setLoading(false);
                            setBranchlist([{id:"", title: "Select Branch"}])
                        })
                }, []);

                useEffect(() => {
                    const requestOptions = {
                        method: 'GET',
                        headers: { 'Content-Type': 'application/json', 'authorization' :  sessionStorage.getItem('access_token') }
                    };
                    if (!!branchcode && branchcode != "-1") {
                        setBdloading(true);
                        fetch(process.env.REACT_APP_API_PREFIX_URL + 'branchdetail/'+branchcode, requestOptions)
                            .then(response => response.json())
                            .then(result => 
                                {
                                    if(result.status === "Failed" && result.message === "Invalid token"){
                                        history.push("/");
                                    }else{
                                        setBranchdetails(result["response"]);
                                        setBdloading(false);
                                    }
                                }).catch(error => {
                                            console.log(error);
                                            setBdloading(false);
                                            setBranchdetails({})
                                        })
                    } else{
                        setBranchdetails({})
                    }
                }, [branchcode, setBranchdetails]);

             const fileselectonchange =(evt) =>{
                let files = evt.target.files;
                console.log(files);

                let reader = new FileReader();
                reader.readAsDataURL(files[0]);
                reader.onload = (e) =>{
                    console.log("file data", e.target.result);
                    const formData = {file :e.target.result };
                    const requestOptions = {
                        method: 'POST',
                        headers: { 'Content-Type': 'application/json', 'authorization' :  sessionStorage.getItem('access_token') },
                        body: JSON.stringify(formData)
                    };
                
                    fetch(process.env.REACT_APP_API_PREFIX_URL + 'inventory/uploadnewproduct', requestOptions)
                    .then(response => response.json())
                    .then(result => {
                        // console.log(result);
                        if(result.status === "Failed" && result.message === "Invalid token"){
                            history.push("/");
                        } else if(result.status == "failed"){
                            setOpen(true);
                            setSeverity('error')
                            setMsg(result.msg);
                            evt.target.value = null;
                            if(result.product_list.length){
                                setErrorDetail(result.product_list);
                            }else {
                                setErrorDetail([]);
                            }
                            return false;
                        } else{
                            setOpen(true);
                            setSeverity('success')
                            setMsg(result.msg);
                            evt.target.value = null;
                            setErrorDetail([]);
                            return false;
                        }
                    }) .catch(error => {
                        console.log("weer",error);
                        setOpen(true);
                        setSeverity('error')
                        setMsg(error);
                        return false;

                    });
                }
            }

    return (
        <div>

            <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
                <Alert onClose={handleClose} severity={severity}>
                    {msg}
              </Alert>
            </Snackbar>
             <Header />

             <div>
                <Typography variant="h6" className={classes.spaceSelectBt}>
                Add Product
                </Typography>
            </div>
            <div>
                <Controls.RadioGroup
                    name= "SelectOptionToAddProduct"
                    items={listOfProductAddOption}
                    value={selectedAddProduct}
                    onChange= {handleProductAddOptionChange}
                />
            </div>
            <>
            { selectedAddProduct == "single" ?
             <Grid container spacing={3} >
                <Grid  item  sm={3}  className = {classes.spaceSelectTop}>
                <div className = {classes.spaceSelect}>
                            <Controls.Select
                                className = {classes.selectWidth}
                                label = "Select Vendor"
                                name="vendorlist"
                                value={vendorCode}
                                onChange={handleInputChangeVendor}
                                options={vendorList}
                                key_id ="seller_code"
                                key_title ="seller_name"
                            />
                        </div>
                </Grid>

               

                { loading ? 
                    
                    <ClipLoader
                        color={color}
                        loading={loading}
                        size={50}
                        cssOverride= { {display: "block",   margin: '0 auto'} }
                    /> :
                    <Grid item  sm={3}>
                        <div className = {classes.spaceSelect}>
                            <Controls.Select
                                className = {classes.selectWidth}
                                label = "Select Poorvika Branch"
                                name="deliverybranch"
                                value={branchcode}
                                onChange={handleInputChange}
                                options={branchlist}
                                key_id ="id"
                                key_title ="title"
                            />
                        </div>
                    </Grid>
                }

                { loading ? 
                    <ClipLoader
                        color={color}
                        loading={loading}
                        size={50}
                        cssOverride= { {display: "block",   margin: '0 auto'} }
                    /> :
                    <Grid item  sm={3}>
                        <div className = {classes.spaceSelect}>
                            <Controls.Input
                                className = {classes.selectWidth}
                                label = "Enter APX Item Code"
                                name="apxitemcode"
                                value={apxitemcode}
                                onChange={handleItemcodeChange}
                            />
                        </div>
                    </Grid>
                }

                { loading ? 
                    <ClipLoader
                        color={color}
                        loading={loading}
                        size={50}
                        cssOverride= { {display: "block",   margin: '0 auto'} }
                    /> :
                    <Grid item  sm={3}>
                        <div className = {classes.spaceSelect}>
                            <Controls.Input
                                className = {classes.selectWidth}
                                label = "Global Product Id"
                                name="ingramproductOrderid"
                                value={globalProductId}
                                onChange={handleGlobalProductChange}
                            />
                        </div>
                    </Grid>
                }


                { loading ? 
                    <ClipLoader
                        color={color}
                        loading={loading}
                        size={50}
                        cssOverride= { {display: "block",   margin: '0 auto'} }
                    /> :
                    <Grid item  sm={6}>
                        <div className = {classes.spaceSelect}>
                            <Controls.Input
                                className = {classes.selectWidth}
                                label = "Product Name"
                                name="productname"
                                value={productname}
                                onChange={handleProductnameChange}
                                styleobj={{ width: 600 }}
                            />
                        </div>
                    </Grid>
                }

{ loading ? 
                    <ClipLoader
                        color={color}
                        loading={loading}
                        size={50}
                        cssOverride= { {display: "block",   margin: '0 auto'} }
                    /> :
                 <Grid  item  sm={3}>
                <div className = {classes.spaceSelect}>
                            <Controls.Select
                                className = {classes.selectWidth}
                                label = "Select Category"
                                name="catagotyList"
                                value={catCode}
                                onChange={handleInputChangeCat}
                                options={catagotyList}
                                key_id ="cat_id"
                                key_title ="cat_name"
                            />
                        </div>
                </Grid>
            }

            { loading ? 
                    <ClipLoader
                        color={color}
                        loading={loading}
                        size={50}
                        cssOverride= { {display: "block",   margin: '0 auto'} }
                    /> :
                <Grid  item  sm={3}>
                <div className = {classes.spaceSelect}>
                            <Controls.Select
                                className = {classes.selectWidth}
                                label = "Select Manufacturer"
                                name="manufacturerList"
                                value={manufacturerId}
                                onChange={handleInputChangeManufacture}
                                options={manufacturerList}
                                key_id ="id"
                                key_title ="name"
                            />
                        </div>
                </Grid>
               
            }

            { loading ? 
                    <ClipLoader
                        color={color}
                        loading={loading}
                        size={50}
                        cssOverride= { {display: "block",   margin: '0 auto'} }
                    /> :
                <Grid  item  sm={3}>
                <div className = {classes.spaceSelect}>
                            <Controls.Select
                                className = {classes.selectWidth}
                                label = "Select Brand"
                                name="brandList"
                                value={manufacturerId}
                                onChange={handleInputChangeBrand}
                                options={brandList}
                                key_id ="id"
                                key_title ="name"
                            />
                        </div>
                </Grid>
               
            }


            {  loading ? 
                    <ClipLoader
                        color={color}
                        loading={loading}
                        size={50}
                        cssOverride= { {display: "block",   margin: '0 auto'} }
                    /> :
                    <Grid item  sm={3}>
                        <div className = {classes.spaceSelect}>
                            <Controls.Input
                                className = {classes.selectWidth}
                                label = "Brand SKU"
                                name="skuid"
                                value={skuid}
                                onChange={handleInputChangeSku}
                            />
                        </div>
                    </Grid>
        }
    
            </Grid>
            :
            
            <>
             <div>
                <Typography variant="h6" className={classes.spaceSelectBt}>
               Select file to Upload
                </Typography>
                <input type="file" name="file" onChange={fileselectonchange}/>
            </div>
            <div className={classes.spaceSelectTop}>
            Note : Use Download template for create products & Do not change header and sheet name from sample downloaded template
            </div>
            <div className={classes.spaceSelectTop}>
                <ExportToExcel apiData={[]} fileName={fileName} heading={MyHeading} displayTxt={"Download Template"}  sheetName={"Product_Details"}/>
            </div>
            {
                errordetail.length > 0 ?  
            <div className={classes.spaceSelectTop}>
                <ExportToExcel btnColor={"secondary"} apiData={errordetail} fileName={fileName_error} heading={MyHeading_Error} displayTxt={"Download Error"}  sheetName={"Product_Details"}/>
            </div> : ("")}
            
           
            </>}
            </>

            {
                createOrderloading ?  
                <div>
                <ClipLoader
                    color={color}
                    loading={createOrderloading}
                    size={50}
                    cssOverride= { {display: "block",   margin: '0 auto'} }
                /> </div>: 
            
            !!apxitemcode && !!globalProductId && !!productname && !!skuid ? (
            <div  className = {classes.alignbutton}>
            <Controls.Button
                        size = "large"
                        text="Add Product"
                         onClick={addProduct}
                  /> 
             <Controls.Button
                      size = "large"
                      text="Cancel"
                       onClick={cancelAddProduct}
                  />
            </div> ) : ("")}

      
            
           
        </div>

      
    )
}


export default Product;