import React from 'react'
import { FormControl, InputLabel, Select as MuiSelect, MenuItem, FormHelperText } from '@material-ui/core';

export default function Select(props) {

    const { name, label, value,error=null, onChange, options , key_id, key_title} = props;

    // console.log("options",options);
    return (
        <FormControl variant="outlined" style={{minWidth: 180}}
        {...(error && {error:true})}>
            <InputLabel>{label}</InputLabel>
            <MuiSelect
                label={label}
                name={name}
                value={value}
                onChange={onChange}>
                {/* <MenuItem value="">None</MenuItem> */}
                {
                    options.map(
                        item => (<MenuItem key={item[key_id]} value={item[key_id]}>{item[key_title]}</MenuItem>)
                    )
                }
            </MuiSelect>
            {error && <FormHelperText>{error}</FormHelperText>}
        </FormControl>
    )
}