import React from 'react'
import { TextField } from '@material-ui/core';

export default function Input(props) {

    const { name, label, value,error=null, onChange,disabled=false,styleobj={ width: 220 }, ...other } = props;
    return (
        <TextField
            variant="outlined"
            label={label}
            name={name}
            value={value}
            onChange={onChange}
            style={styleobj}
            disabled={disabled}
            {...other}
            {...(error && {error:true,helperText:error})}
        />
    )
}