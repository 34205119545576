import React, { useState, useEffect, useCallback } from 'react'
import { makeStyles, TableBody, TableRow, TableCell,Table, TableSortLabel, TableHead } from '@material-ui/core';
import Header from "../components/header";
import Typography from '@material-ui/core/Typography';
import Controls from "../components/controls/Controls";
import Grid from '@material-ui/core/Grid';
import MuiAlert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';
import DeleteIcon from '@material-ui/icons/Delete';
import AddBoxIcon from '@material-ui/icons/AddBox';
import IndeterminateCheckBoxIcon from '@material-ui/icons/IndeterminateCheckBox';
import ClipLoader from "react-spinners/ClipLoader";
import { useHistory } from "react-router-dom"

const useStyles = makeStyles(theme => ({
    root: {
      width: '100%',
      '& > * + *': {
        marginTop: theme.spacing(2),
      },
    },pageContent: {
        marginTop: theme.spacing(5),
        padding: theme.spacing(3)
    },
    searchInput: {
        width: '50%'
    },
    spaceSelect: {
        margin: '0px'
    },
    spaceSelectBt: {
        marginBottom: '5px'
    },
    spaceSelectReq: {
        marginLeft: '12px'
    },
    selectWidth:{
        width: '100px'
    },success : {
        color: 'green'
    },
    failed : {
        color: 'red'
    },
    addbutton:{
        width: '100px',
        marginTop: '11px'
    },
    alignbutton : {
        textAlign:'center',
        marginTop: '15px'
    },
    bigFont: {
        fontSize: '22px'
    },
    boldnumber:{
        fontSize: '1.25rem',
        fontWeight: 900,
        lineHeight: 1.6,
        letterSpacing: '0.0075em'

    },
    boldnumberQty:{
        fontSize: '1.25rem',
        fontWeight: 900,
        lineHeight: 1.6,
        letterSpacing: '0.0075em',
        marginRight: '3rem'

    },
    grandRow:{
        textAlign: 'right',
        marginRight: '10rem'
    }
}));

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

const headCells = [
    { id: 'id', label: 'S.No' },
    { id: 'item_code', label: 'Item Detail' },
    { id: 'ingram_part_number', label: 'Part No' },
    { id: 'seller_customer_price', label: 'Rate' },
    { id: 'quantity', label: 'Quantity' },
    { id: 'amount', label: 'Amount' },
    { id: '', label: 'Action' },
   
];

const Heading = [
    {
        t: 's',
        v: 'Item Detail'
    },{
        t: 's',
        v: 'Part No'
    },
    {
        t: 's',
        v: 'Rate'
    }, {
        t: 's',
        v: 'Quantity'
    },{
        t: 's',
        v:  'Amount'
    }, {
        t: 's',
        v: 'Action'
    }
];
let MyHeading = [];
MyHeading[0] = Heading;

const Order = () => {
    let history = useHistory(); 

    const classes = useStyles();
    const [branchlist,setBranchlist] = useState([{id:"-1", title: "Select Branch"}]);
    const [branchcode, setBranchcode] = useState("-1");
    const [apxitemcode, setApxitemcode] = useState("");
    const [erpitemcode, setErpitemcode] = useState("");

    const [records, setRecords] = useState([]);
    const [branchdetail, setBranchdetails] = useState({});
    const [erppartno, setErppartno] = useState("");
    const [ingrampartno, setIngrampartno] = useState("");
    const [stock, setStock] = useState({});
    const [needstockcount, setNeedstockcount] = useState("");
    const [products, setProducts] = useState([]);

    const [open, setOpen] = React.useState(false);
    const [severity, setSeverity] = React.useState("success");
    const [msg, setMsg] =  React.useState("");

    let [color, setColor] = useState("#226cd7");
    let [loading, setLoading] = useState(false);
    let [bdloading, setBdloading] = useState(false);
    let [spnoloading, setSpnoloading] = useState(false);
    let [stockloading, setStockloading] = useState(false);
    let [createOrderloading, setCreateOrderloading] = useState(false);

    let [allowaccesstoaddprod,setAllowaccesstoaddprod] = useState(false);
   
    const [grandtotal, setGrandtotal] =  React.useState(0);
    const [totalQuantity, setTotalQuantity] =  React.useState(0);

    const handleInputChange = (evt) => {
        setBranchcode(evt.target.value);
    }

    const handleItemcodeChange = (evt) =>{
        setErpitemcode(evt.target.value)
        setAllowaccesstoaddprod(false);
    }

    const handleERPIngramInputChange = (evt) => {
        setErppartno(evt.target.value);
        const erp_and_part_no = evt.target.value.split("-");
        setErpitemcode(erp_and_part_no[0]);
        setIngrampartno(erp_and_part_no[1]);
        setNeedstockcount("");
        setStock([]);
     }

    const handleNeedStockChange = (evt) => {
        setNeedstockcount(evt.target.value)
    }

    useEffect(() => {
        setLoading(true);
        const requestOptions = {
            method: 'GET',
            headers: { 'Content-Type': 'application/json', 'authorization' :  sessionStorage.getItem('access_token') }
        };
        fetch(process.env.REACT_APP_API_PREFIX_URL + 'branchdetail/list', requestOptions)
            .then(response => response.json())
            .then(result => {
                if(result.status === "Failed" && result.message === "Invalid token"){
                    history.push("/");
                }else{
                    setBranchlist(result["response"]);
                    if(result["response"].length > 1){
                        setBranchcode(result["response"][1]["id"])
                    }
                    // setBranchcode()
                    setLoading(false);
                }
            }).catch(error => {
                            console.log(error);
                            setLoading(false);
                            setBranchlist([{id:"", title: "Select Branch"}])
                        })
                }, []);


    useEffect(() => {
        if (!!branchcode && branchcode != "-1") {
            const requestOptions = {
                method: 'GET',
                headers: { 'Content-Type': 'application/json', 'authorization' :  sessionStorage.getItem('access_token') }
            };
            setBdloading(true);
            fetch(process.env.REACT_APP_API_PREFIX_URL + 'branchdetail/'+branchcode, requestOptions)
                .then(response => response.json())
                .then(result => 
                    {
                        if(result.status === "Failed" && result.message === "Invalid token"){
                            history.push("/");
                        }else{
                            setBranchdetails(result["response"]);
                            setBdloading(false);
                        }
                    }).catch(error => {
                                console.log(error);
                                setBdloading(false);
                                setBranchdetails({})
                            })
        } else{
            setBranchdetails({})
        }
    }, [branchcode, setBranchdetails]);



    useEffect(() => {
        if(!erpitemcode){
            setRecords([]);
            setIngrampartno("");
            setApxitemcode("");
            setSpnoloading(false);
            setStock([]);
            setStockloading(false);
            return false;
        }
        setSpnoloading(true);

        const requestOptions = {
            method: 'GET',
            headers: { 'Content-Type': 'application/json', 'authorization' :  sessionStorage.getItem('access_token') }
        };
        
        fetch(process.env.REACT_APP_API_PREFIX_URL + 'inventory/report/stockdetail?search='+erpitemcode+'&stock=apxitemcodesearch', requestOptions)
          .then(response => response.json())
          .then(result => {
            if(result.status === "Failed" && result.message === "Invalid token"){
                history.push("/");
            }else{
                setRecords(result["response"])
                if(result["response"].length > 0){
                    setErppartno(result["response"][0]['psn']+"-"+result["response"][0]['ext_prod_id']);
                    setIngrampartno(result["response"][0]['ext_prod_id'])
                    setApxitemcode(result["response"][0]['psn'])
                }else{
                    setIngrampartno("");
                    setApxitemcode("")
                    setStock([]);
                    setStockloading(false);
                }
                setSpnoloading(false);
            }
          })
            .catch(error => {
                console.log(error);
                setRecords([]);
                setIngrampartno("");
                setErppartno("");
                setSpnoloading(false);
            })
    }, [erpitemcode ,setRecords, setIngrampartno]);



    useEffect(() => {
        if(!!ingrampartno){
            setStockloading(true);
            const requestOptions = {
                method: 'GET',
                headers: { 'Content-Type': 'application/json', 'authorization' :  sessionStorage.getItem('access_token') }
            };
            fetch(process.env.REACT_APP_API_PREFIX_URL + 'porder/getstockandprice/'+ingrampartno, requestOptions)
            .then(response => response.json())
            .then(result => {
                if(result.status === "Failed" && result.message === "Invalid token"){
                    history.push("/");
                }else{
                    setStock(result["response"]);
                    setStockloading(false);
                }
            })
                .catch(error => {
                    console.log(error);
                    setStock([]);
                    setStockloading(false);
                })
        } 
    }, [ingrampartno, setStock]);

    
   const addProductTolist = (evt) =>     {
    const num_needstockcount = !!needstockcount ? parseInt(needstockcount) : 0;
    const availableQty = stock.serviceresponse.priceandstockresponse.details[0]["warehousedetails"][0]["availablequantity"];
        if((num_needstockcount > 0 && num_needstockcount<= availableQty) || allowaccesstoaddprod){
            const amountTotal = parseInt(needstockcount) * parseFloat(stock.serviceresponse.priceandstockresponse.details[0]["customerprice"]);
            const prod = {
                "seller_customer_price": stock.serviceresponse.priceandstockresponse.details[0]["customerprice"],
                "quantity" : parseInt(needstockcount),
                "item_code" : apxitemcode,
                "ingram_part_number": ingrampartno,
                "name":stock.serviceresponse.priceandstockresponse.details[0]["partdescription1"],
                "available_stock":availableQty,
                "amount":Math.floor(amountTotal * 100) / 100
            };

            let curList = products;
            let updatedList = [...curList, prod]
            let grandtaotal = 0;
            let totalQty = 0;
            updatedList.forEach(proditem => {
                grandtaotal += proditem["amount"]
                totalQty +=  proditem["quantity"]
            });
            setGrandtotal(grandtaotal);
            setTotalQuantity(totalQty);
            // curList.push(prod);
            setProducts(updatedList)
            setApxitemcode("");
            setErpitemcode("");
            setErppartno("");
            setRecords([]);
            setIngrampartno("");
            setNeedstockcount("");
            setStock([]);
        } else {
            setOpen(true);
            setSeverity('error')
            setMsg("Please enter valid quantity.");
        }
    }

    const removeProductFromlist = (productIdx) => {
        const newProducts = [...products];
        newProducts.splice(productIdx, 1);
        setProducts(newProducts);
    }

    const createPurchesOrder = (evt) => {
        let poObject = {
            "delivery_branch_state": branchdetail.state,
            "selected_branchcode": branchcode,
            "product_detail_list": products
        }

        console.log("poObject",poObject)

        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', 'authorization' :  sessionStorage.getItem('access_token')  },
            body: JSON.stringify(poObject)
        };

        setCreateOrderloading(true);

        fetch(process.env.REACT_APP_API_PREFIX_URL + 'porder/create', requestOptions)
            .then(response => response.json())
            .then(result => {
                if(result.status === "Failed" && result.message === "Invalid token"){
                    history.push("/");
                }else{
                    if(result.status == "success") {
                        setOpen(true);
                        setSeverity('success')
                        setMsg(result.response);
                        setApxitemcode("");
                        setRecords([]);
                        setIngrampartno("");
                        setNeedstockcount("");
                        setErpitemcode("");
                        setErppartno("");
                        setStock([]);
                        setProducts([]);
                        setBranchcode("");
                        setBranchdetails({})
                        setCreateOrderloading(false);
                    } else {
                        setOpen(false);
                        setSeverity('error')
                        setMsg(result.response);
                        setCreateOrderloading(false);
                    }
                }
               
             })
                .catch(error => {
                    console.log(error);
                    setCreateOrderloading(false);
                 })

    }

    const cancelPurchesOrder = (evt) => {
        setApxitemcode("");
        setRecords([]);
        setIngrampartno("");
        setNeedstockcount("");
        setErpitemcode("");
        setErppartno("");
        setStock([]);
        setProducts([]);
        setBranchcode("");
        setBranchdetails({});
    }

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
        setOpen(false);
      };


      const addQuantityProduct = (evt , idx) => {
        const newProducts = [...products];
        let product = products[idx];

        let qty =  parseInt(product["quantity"]);
        const existStock = parseInt(product["available_stock"]);
        const rate = parseFloat(product["seller_customer_price"]);
        qty =qty +1 ;
        if((qty <= existStock) || allowaccesstoaddprod) {
            product["quantity"] = qty;
            let amount = qty * rate;
            product["amount"] =  Math.floor(amount * 100) / 100;

            newProducts[idx] = product;
            setProducts(newProducts);
            let grandtaotal = 0;
            let totalQty = 0;
            newProducts.forEach(proditem => {
                grandtaotal += proditem["amount"]
                totalQty += proditem["quantity"]
            });
            setGrandtotal(grandtaotal);
            setTotalQuantity(totalQty)
        }  else {
            //Show Error
            setOpen(true);
            setSeverity('error')
            setMsg("Requested quantity cannot be more than stock.");
        }


      }

      const reduceQuantityProduct = (evt, idx) => {
        const newProducts = [...products];
        let product = products[idx];
        let qty =  parseInt(product["quantity"]);
        const rate = parseFloat(product["seller_customer_price"]);
        qty =qty - 1 ;
        if(qty >= 1){
            product["quantity"] = qty;
            let amount = qty * rate;
            product["amount"] =  Math.floor(amount * 100) / 100;
            newProducts[idx] = product;
            setProducts(newProducts);
            let grandtaotal = 0;
            let totalQty = 0;
            newProducts.forEach(proditem => {
                grandtaotal += proditem["amount"]
                totalQty += proditem["quantity"]
            });
            setGrandtotal(grandtaotal);
            setTotalQuantity(totalQty)
        } else {
            //Show Error
            setOpen(true);
            setSeverity('error')
            setMsg("Requested quantity cannot be less than one.");
        }
      }

      const handleAllowZeroQuantity = () =>{
        setAllowaccesstoaddprod(!allowaccesstoaddprod);
      }

    
    // console.log("productArray",products);

    return (
        <div>

            <Header />

            <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
                <Alert onClose={handleClose} severity={severity}>
                    {msg}
              </Alert>
            </Snackbar>

            <div>
                <Typography variant="h6" className={classes.spaceSelectBt}>
                Create Purchase Order
                </Typography>
            </div>
            <Grid container spacing={3}>
            <Grid  item  sm={3}>
                <div className = {classes.spaceSelect}>
                <Controls.Input
                            className = {classes.selectWidth}
                            label = "Vendor"
                            name="vendorname"
                            value={"Ingram Micro India Private Limited"}
                            disabled={true}
                        />
            </div>

            </Grid>

            { loading ? 
                    
                    <ClipLoader
                        color={color}
                        loading={loading}
                        size={50}
                        cssOverride= { {display: "block",   margin: '0 auto'} }
                    /> :
                    <Grid item  sm={3}>
                        <div className = {classes.spaceSelect}>
                            <Controls.Select
                                className = {classes.selectWidth}
                                label = "Select Poorvika Branch"
                                name="deliverybranch"
                                value={branchcode}
                                onChange={handleInputChange}
                                options={branchlist}
                                key_id ="id"
                                key_title ="title"
                            />
                        </div>
                    </Grid>
                }

            {
                bdloading ? 
                <Grid item sm={6}>  
                <ClipLoader
                    color={color}
                    loading={bdloading}
                    size={50}
                    cssOverride= { {display: "block",   margin: '0 auto'} }
                /> 
                </Grid>
                : !!branchcode && !!branchdetail && !!branchdetail.address ? (
                    <Grid item sm={6}>  
                      <Typography variant="h6" className={classes.title}>
                      Delivery Location: 
                      </Typography>
                      {branchdetail.address}
                      </Grid>
                  ) : ("")

            }
    
            

                {
                bdloading ? 
                <Grid item sm={3}>  
                <ClipLoader
                    color={color}
                    loading={bdloading}
                    size={50}
                    cssOverride= { {display: "block",   margin: '0 auto'} }
                />  </Grid>

                : !!branchcode && branchcode != "-1" ? (
              <Grid item  sm={3}>
              <div className = {classes.spaceSelect}>
                  <Controls.Input
                      className = {classes.selectWidth}
                      label = "Enter ERP / Ingram / Manufacture no:"
                      name="apxitemcode"
                      value={erpitemcode}
                      onChange={handleItemcodeChange}
                  />
              </div>
          </Grid>
            ) : ("")
            
            }   

            {
                spnoloading ?  
                <Grid item  sm={3}>
                <ClipLoader
                    color={color}
                    loading={spnoloading}
                    size={50}
                    cssOverride= { {display: "block",   margin: '0 auto'} }
                /></Grid> : !!erpitemcode && !!branchcode && branchcode != "-1"  && records.length > 0? (
                    <Grid item  sm={3}>
                    <div className = {classes.spaceSelect}>
                          <Controls.Selecttext
                              className = {classes.selectWidth}
                              label = "Select ERP - Part No"
                              name="selectpartno"
                              value={erppartno}
                              onChange={handleERPIngramInputChange}
                              key_id ="psn"
                              key_id_1 ="ext_prod_id"
                              key_title ="psn"
                              key_title_1 ="ext_prod_id"
                              options={records}
      
                          />
                      </div>
                </Grid>
                  ) : ("")
            }
            
            {
                stockloading ?  
                <Grid item  sm={3}>
                <ClipLoader
                    color={color}
                    loading={stockloading}
                    size={50}
                    cssOverride= { {display: "block",   margin: '0 auto'} }
                /></Grid> :

            !!stock && !!stock.serviceresponse && !!stock.serviceresponse.responsepreamble && !!stock.serviceresponse.responsepreamble.responsemessage
            && stock.serviceresponse.responsepreamble.responsemessage == "Data Found" && !!stock.serviceresponse.priceandstockresponse
            && !!stock.serviceresponse.priceandstockresponse.details && !!stock.serviceresponse.priceandstockresponse.details[0] &&
            !!stock.serviceresponse.priceandstockresponse.details[0]["warehousedetails"]
            ? (
              <Grid item sm={6}>  
                <Typography variant="h6" className={classes.title}>
                Product Description : {stock.serviceresponse.priceandstockresponse.details[0]["partdescription1"]}
                </Typography>
                { stock.serviceresponse.priceandstockresponse.details[0]["warehousedetails"][0]["availablequantity"] > 0 ? (
                <>
                <Typography variant="h6" className={classes.success}>
                Available Stock: {stock.serviceresponse.priceandstockresponse.details[0]["warehousedetails"][0]["availablequantity"]}
                </Typography>
                <label>
                        <input
                        className={classes.title}
                        type="checkbox"
                        checked={allowaccesstoaddprod}
                        onChange={handleAllowZeroQuantity}
                        />
                    Allow to Create Purchase Order and Global Order
                    </label>
                </>
                ) : 
                (
                    <>
                    <Typography variant="h6" className={classes.failed}>
                    Available Stock : {stock.serviceresponse.priceandstockresponse.details[0]["warehousedetails"][0]["availablequantity"]}
                    </Typography>
                    <label>
                        <input
                        className={classes.title}
                        type="checkbox"
                        checked={allowaccesstoaddprod}
                        onChange={handleAllowZeroQuantity}
                        />
                    Allow to Create Purchase Order and Global Order
                    </label>
                    </>
                ) }
                </Grid>
            ) : ("") 

                }

        {!!stock && !!stock.serviceresponse && !!stock.serviceresponse.responsepreamble && !!stock.serviceresponse.responsepreamble.responsemessage
            && stock.serviceresponse.responsepreamble.responsemessage == "Data Found" && !!stock.serviceresponse.priceandstockresponse
            && !!stock.serviceresponse.priceandstockresponse.details && !!stock.serviceresponse.priceandstockresponse.details[0] &&
            !!stock.serviceresponse.priceandstockresponse.details[0]["warehousedetails"]
            && (stock.serviceresponse.priceandstockresponse.details[0]["warehousedetails"][0]["availablequantity"] >0 || allowaccesstoaddprod)
            ? (
              <Grid item sm={3}>  
                <div className = {classes.spaceSelect}>
                  <Controls.Input
                      className = {classes.selectWidth}
                      label = "Enter Required Stock"
                      name="needstockcount"
                      value={needstockcount}
                      onChange={handleNeedStockChange}
                  />
                  <span className = {classes.spaceSelectReq}></span>
                  <Controls.Button
                      className = {classes.addbutton}
                      size = "small"
                      text="Add Product"
                      onClick={addProductTolist}
                  />
              </div>
              </Grid>
            ) : ("")}   
    
            </Grid>
            {!!products && !!products.length ? (
                <div>
                <Table>
                    <TableHead>
                        <TableRow key="headerrow">
                            {
                                headCells.map(headCell => (
                                    <TableCell>
                                        {headCell.disableSorting ? headCell.label :
                                            <TableSortLabel>
                                                  {headCell.label}
                                            </TableSortLabel>
                                        }
                                    </TableCell>))
                            }
                        </TableRow>
                    </TableHead>
                    <TableBody> 
                        {
                            products.map((prod, index) =>
                                (<TableRow key={index}>
                                    <TableCell>{index + 1}</TableCell>
                                    <TableCell>{prod.name} - {prod.item_code}</TableCell>
                                    <TableCell>{prod.ingram_part_number}</TableCell>
                                    <TableCell>{prod.seller_customer_price}</TableCell>
                                    <TableCell>
                                        <IndeterminateCheckBoxIcon className = {classes.failed} onClick={(evt) => reduceQuantityProduct(evt, index)}></IndeterminateCheckBoxIcon>
                                        <span className={classes.bigFont}>{prod.quantity}</span>
                                        <AddBoxIcon className = {classes.success} onClick={(evt) => addQuantityProduct(evt, index)}></AddBoxIcon>

                                    </TableCell>
                                    <TableCell>Rs.{prod.amount.toLocaleString('en-IN', { minimumFractionDigits: 2 })}</TableCell>
                                    <TableCell><DeleteIcon  onClick={(evt) => removeProductFromlist(evt, index)}></DeleteIcon></TableCell>
                                </TableRow>)
                            )
                        }
                    </TableBody>
                </Table>
                <div className={classes.grandRow}>
                <Typography variant="span" >
                      Grand Quantity: <Typography variant="span" className={classes.boldnumberQty}> {totalQuantity}
</Typography>
                      </Typography>
                <Typography variant="span" >
                      Grand Total: <Typography variant="span" className={classes.boldnumber}>Rs. {grandtotal.toLocaleString('en-IN', { minimumFractionDigits: 2 })
}</Typography>
                      </Typography>
                </div>
                
                </div>
             ) : ("")}
             

{
                createOrderloading ?  
                <div>
                <ClipLoader
                    color={color}
                    loading={createOrderloading}
                    size={50}
                    cssOverride= { {display: "block",   margin: '0 auto'} }
                /> </div>: 
            
            !!products && !!products.length ? (
            <div  className = {classes.alignbutton}>
            <Controls.Button
                       size = "large"
                      text="Create Purchase & Global Order"
                       onClick={createPurchesOrder}
                  /> 
             <Controls.Button
                       size = "large"
                      text="Cancel"
                       onClick={cancelPurchesOrder}
                  />
            </div> ) : ("")}
           



        </div>
    )


}


export default Order;