import React, { useState, useEffect,Fragment } from 'react'
import { makeStyles, TableBody, TableRow, TableCell, Toolbar } from '@material-ui/core';
import useTable from "../components/useTable";
import Header from "../components/header";
import { DatePicker } from "@material-ui/pickers";
import Typography from '@material-ui/core/Typography';
import ClipLoader from "react-spinners/ClipLoader";
import { useHistory } from "react-router-dom"

const useStyles = makeStyles(theme => ({
    root: {
      width: '100%',
      '& > * + *': {
        marginTop: theme.spacing(2),
      },
    },pageContent: {
        marginTop: theme.spacing(5),
        padding: theme.spacing(3)
    },
    searchInput: {
        width: '50%'
    },
    spaceSelect: {
        margin: '20px'
    },
    pullright: {
        position: 'absolute',
        right: 10,
        fontWeight: 'bold'
    },
    title: {
        margin: '20px',
        color: '#1a1ab7'
    },
    success : {
        color: 'green'
    },
    failed : {
        color: 'red'
    }
}));

const headCells = [
    { id: 'job_id', label: 'Job ID' },
    { id: 'job_name', label: 'Job Name' },
    { id: 'row_added_dttm', label: 'Job Start Date' },
    { id: 'row_update_dttm', label: 'Job End Date' },
    { id: 'time_taken', label: 'Time Taken(Seconds)' },
    { id: 'status', label: 'Job Status' },
   
];

const Heading = [
    {
        t: 's',
        v: 'Job ID'
    },{
        t: 's',
        v: 'Job Name'
    },{
        t: 's',
        v: 'Job Start Date'
    },{
        t: 's',
        v:  'Job End Date'},
    {
        t: 's',
        v: 'Time Taken(Seconds)'
    }, {
        t: 's',
        v: 'Job Status'
    }
];
let MyHeading = [];
MyHeading[0] = Heading;

export default function SLogs() {
    let history = useHistory(); 

    const classes = useStyles();
    const [records, setRecords] = useState([]);
    let [color, setColor] = useState("#226cd7");
    let [loading, setLoading] = useState(false);
    // const [filterFn, setFilterFn] = useState({ fn: items => { return items; } });
    const [selectedStartDate, setSelectedStartDate] = useState(
         () => {
            const date_today = new Date();
            // const previous = new Date(datet.getTime());
            // previous.setDate(datet.getDate() - 1);

            let month = date_today.getMonth() +1;
            let date = date_today.getDate();
            if(date < 10){
                date = "0"+date;
            }
            if(month < 10){
                month = "0"+month;
            }
            return new Date().getFullYear() +"-" + month + "-" + date;

        // let d = new Date();
        // // let month = d.getMonth();
        // // d.setMonth(d.getMonth() - 1);
        // // while (d.getMonth() === month) {
        // //     d.setDate(d.getDate() - 1);
        // // }
        // // let date = d.getDate();
        // if(date < 10){
        //     date = "0"+date;
        // }
        // if(month < 10){
        //     month = "0"+month;
        // }
        // return new Date().getFullYear() +"-" + month + "-" + date;

    }

    );
    const [selectedEndDate, setSelectedEndDate] = useState(
            () => {

                let month = new Date().getMonth()+1;
                let date = new Date().getDate();
        
                if(date < 10){
                    date = "0"+date;
                }
                if(month < 10){
                    month = "0"+month;
                }
                return new Date().getFullYear() +"-" + month + "-" + date;
        
            }
    
    );

    useEffect(() => {
        // console.log(selectedStartDate,"start");
        // console.log(selectedEndDate,"end");
        setLoading(true);
        const requestOptions = {
            method: 'GET',
            headers: { 'Content-Type': 'application/json', 'authorization' :  sessionStorage.getItem('access_token') }
        };
        fetch(process.env.REACT_APP_API_PREFIX_URL + 'auditjob/report?startdate='+selectedStartDate+'&enddate='+selectedEndDate, requestOptions)
          .then(response => response.json())
          .then(result => {
            if(result.status === "Failed" && result.message === "Invalid token"){
                history.push("/");
            }else{
                setRecords(result["response"]);
                setLoading(false);
            }
          })
            .catch(error => {
                console.log(error);
                setRecords([])
            })
    }, [selectedStartDate, selectedEndDate]);

    const {
        TblContainer,
        TblHead,
        TblPagination,
        recordsAfterPagingAndSorting,
        getTotalRecords,
        getTotalSuccessecords,
        getTotalFailedecords
    } = useTable(records, headCells);


    const handleDateChange1 = (sdate) => {
        let month = sdate.getMonth()+1;
        let date = sdate.getDate();

        if(date < 10){
            date = "0"+date;
        }
        if(month < 10){
            month = "0"+month;
        }
        setSelectedStartDate( sdate.getFullYear() +"-" + month + "-" + date);  
    }

    const handleDateChange2 = (edate) => {
        let month = edate.getMonth()+1;
        let date = edate.getDate();

        if(date < 10){
            date = "0"+date;
        }
        if(month < 10){
            month = "0"+month;
        }
        setSelectedEndDate( edate.getFullYear() +"-" + month + "-" + date);  
    }

    return (
        <div>
            <Header />

            <div >
                <div>
                    <Typography variant="h6" className={classes.title}>
                    Ingram Stock Sync Scheduler Log
                    </Typography>
                </div>
            <Toolbar>
            <Fragment>
            <DatePicker 
             label="Start Date"
            value={selectedStartDate} 
            onChange={handleDateChange1} 
            format="yyyy-MM-dd"
            />
            <div className = {classes.spaceSelect}></div>
            <DatePicker 
             label="End Date"
            value={selectedEndDate} 
            onChange={ handleDateChange2} 
            format="yyyy-MM-dd"
               />
              </Fragment>
              <div className = {classes.spaceSelect}></div>
              <div className = {classes.spaceSelect}></div>
              <Typography> 
                Total  Executions: &nbsp;{getTotalRecords()}&nbsp; <br/> 
                Successful Executions: <span className= {classes.success}>{getTotalSuccessecords()}</span> <br/>
                Failed Executions: <span  className= {classes.failed}> {getTotalFailedecords()} </span>
            </Typography>
            <div className = {classes.spaceSelect}></div>
            {/* <div variant="h6" align="right" className={classes.pullright}> Total &nbsp;{getTotalRecords()}&nbsp;
             no of records found. </div> */}
           
            </Toolbar>

            { loading ? 
                    <div>
                    <ClipLoader
                        color={color}
                        loading={loading}
                        size={50}
                        cssOverride= { {display: "block",   margin: '0 auto'} }
                    /> </div>:
                    <div>
                <TblContainer>
                    <TblHead />
                    <TableBody>
                        {
                            recordsAfterPagingAndSorting().map((job, index) =>
                                (<TableRow key={index}>
                                    <TableCell>{job.job_id}</TableCell>
                                    <TableCell>{job.job_name}</TableCell>
                                    <TableCell>{job.row_added_dttm}</TableCell>
                                    <TableCell>{job.row_update_dttm}</TableCell>
                                    <TableCell>{job.time_taken}</TableCell>
                                    <TableCell>{job.status}</TableCell>
                                </TableRow>)
                            )
                        }
                    </TableBody>
                </TblContainer>
                <TblPagination />
                </div>
            }
            </div>
        </div>
    )
}